<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert"
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form
              ref="form"
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.name"
                    :disabled="form.loading"
                    :counter="50"
                    :label="$t('PROMOTION.ADD_EDIT.NAME')"
                    :rules="form.nameRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.order"
                    :disabled="form.loading"
                    :label="$t('PROMOTION.ADD_EDIT.ORDER')"
                    :rules="form.orderRules"
                    type="number"
                    min="1"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="form.description"
                    :disabled="form.loading"
                    :counter="2000"
                    :label="$t('PROMOTION.ADD_EDIT.DESCRIPTION_TH')"
                    :rules="form.descriptionRules"
                    required
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="form.descriptionEN"
                    :disabled="form.loading"
                    :counter="2000"
                    :label="$t('PROMOTION.ADD_EDIT.DESCRIPTION_EN')"
                    :rules="form.descriptionENRules"
                    required
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="startDateMenu"
                    v-model="form.startDateMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedStartDateFormatted"
                        :disabled="form.loading"
                        :label="$t('PROMOTION.ADD_EDIT.START_DATE')"
                        :rules="form.startDateRules"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.startDate"
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.startDateMenu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startDateMenu.save(form.startDate)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="startTimeMenu"
                    v-model="form.startTimeMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.startTime"
                        :disabled="form.loading"
                        :label="$t('PROMOTION.ADD_EDIT.START_TIME')"
                        :rules="form.startTimeRules"
                        hint="HH:MM format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="form.startTime"
                      format="24hr"
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.startTimeMenu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startTimeMenu.save(form.startTime)"
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="endDateMenu"
                    v-model="form.endDateMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedEndDateFormatted"
                        :disabled="form.loading"
                        :label="$t('PROMOTION.ADD_EDIT.END_DATE')"
                        :rules="form.endDateRules"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.endDate"
                      scrollable
                      :disabled="form.loading"
                      :min="form.startDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.endDateMenu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDateMenu.save(form.startDate)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="endTimeMenu"
                    v-model="form.endTimeMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.endTime"
                        :disabled="form.loading"
                        :label="$t('PROMOTION.ADD_EDIT.END_TIME')"
                        :rules="form.endTimeRules"
                        hint="HH:MM format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="form.endTime"
                      format="24hr"
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.endTimeMenu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endTimeMenu.save(form.endTime)"
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="12">
                  <v-file-input
                    accept="image/*"
                    show-size
                    v-model="form.picture"
                    :disabled="form.loading"
                    :label="$t('PROMOTION.ADD_EDIT.PICTURE_TH')"
                    :rules="form.pictureRules"
                    :hint="$t('PROMOTION.ADD_EDIT.PICTURE_HINT')"
                    persistent-hint
                    required
                  ></v-file-input>
                </v-col>

                <v-col cols="12" md="12">
                  <v-file-input
                    accept="image/*"
                    show-size
                    v-model="form.pictureEN"
                    :disabled="form.loading"
                    :label="$t('PROMOTION.ADD_EDIT.PICTURE_EN')"
                    :rules="form.pictureENRules"
                    :hint="$t('PROMOTION.ADD_EDIT.PICTURE_HINT')"
                    persistent-hint
                    required
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox
                    v-model="form.showOnPrivilegePage"
                    :disabled="form.loading"
                    :label="$t('PROMOTION.ADD_EDIT.SHOW_ON_PRIVILEGE_PAGE')"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="form.pointToRedeem"
                    :disabled="form.loading"
                    :label="$t('PROMOTION.ADD_EDIT.POINT_TO_REDEEM')"
                    :rules="form.pointToRedeemRules"
                    type="number"
                    min="0"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
               <v-row>
                <v-col cols="12" md="12">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.generateRefCode"
                      :items="form.generateRefCodeItems"
                      :loading="form.generateRefCodeLoading"
                      :search-input.sync="form.generateRefCodeSearch"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('MEMBER_CARD.SEARCH.GENERATE_REF_CODE')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH') +
                          $t('MEMBER_CARD.SEARCH.GENERATE_REF_CODE')
                      "
                      
                      return-object
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          {{ data.item.value }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.value"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('PROMOTION.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("PROMOTION.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}</v-card-title
                  >
                  <v-card-text>
                    {{ $t("PROMOTION.ADD_EDIT.ADD_SUCCESS_DIALOG_TEXT") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDialog">{{
                      $t("SHARED.CLOSE_BUTTON")
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  
  data() {
    return {
      form: {
        valid: true,
        dialog: false,
        loading: false,
        errors: [],
        name: "",
        description: "",
        descriptionEN: "",
        order: null,
        startDateMenu: false,
        startDate: null,
        startDateFormatted: this.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        // minStartDate: new Date().toISOString().substr(0, 10),
        startTime: "00:00",
        startTimeMenu: false,
        endDateMenu: false,
        endDate: null,
        endDateFormatted: this.formatDate(
          this.getTomorrowDate()
            .toISOString()
            .substr(0, 10)
        ),
        // minEndDate: this.getTomorrowDate()
        //   .toISOString()
        //   .substr(0, 10),
        endTime: "00:00",
        endTimeMenu: false,
        inActiveStatus: true,
        picture: null,
        pictureEN: null,
        pictureType: ["image/jpeg", "image/png"],
        showOnPrivilegePage: false,
        pointToRedeem: 0,
        generateRefCode: [],
        generateRefCodeSearch: "",
        generateRefCodeLoading: false,
        generateRefCodeItems: [],
        generateRefCodeList: [],
        nameRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.NAME_VALIDATION"),
          (v) =>
            (v && v.length <= 50) || this.$t("PROMOTION.ADD_EDIT.NAME_COUNTER"),
        ],
        descriptionRules: [
          (v) =>
            !v ||
            (v && v.length <= 2000) ||
            this.$t("PROMOTION.ADD_EDIT.PHONE_NUMBER_COUNTER"),
        ],
        descriptionENRules: [
          (v) =>
            !v ||
            (v && v.length <= 2000) ||
            this.$t("PROMOTION.ADD_EDIT.PHONE_NUMBER_COUNTER"),
        ],
        orderRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.ORDER_VALIDATION"),
          (v) => v > 0 || this.$t("PROMOTION.ADD_EDIT.ORDER_GT_0"),
        ],
        startDateRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.START_DATE_VALIDATION"),
        ],
        endDateRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.END_DATE_VALIDATION"),
        ],
        startTimeRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.START_TIME_VALIDATION"),
        ],
        endTimeRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.END_TIME_VALIDATION"),
        ],
        pictureRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.PICTURE_VALIDATION"),
        ],
        pictureENRules: [
          (v) => !!v || this.$t("PROMOTION.ADD_EDIT.PICTURE_VALIDATION"),
        ],
        pointToRedeemRules: [
          (v) =>
            Number(v) >= 0 ||
            this.$t("PROMOTION.ADD_EDIT.POINT_TO_REDEEM_VALIDATION"),
        ],
      },
    };
  },
  computed: {
    title() {
      return this.$t("MENU.PROMOTION.ADD");
    },
    computedStartDateFormatted() {
      return this.formatDate(this.form.startDate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.form.endDate);
    },
  },
  watch: {
    "form.startDate": {
      handler() {
        this.form.startDateFormatted = this.formatDate(this.form.startDate);
      },
    },
    "form.endDate": {
      handler() {
        this.form.endDateFormatted = this.formatDate(this.form.endDate);
      },
    },
    "form.generateRefCodeSearch": {
      handler() {
        this.getGenerateRefCodeFromApi().then((data) => {
          this.form.generateRefCodeItems = data;
        });
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getTomorrowDate() {
      var today = new Date();
      var tomorrow = today;
      tomorrow.setDate(new Date().getDate() + 1);
      return tomorrow;
    },
    getFileBase64(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = () => {
          //console.log(file);
          resolve({
            url: reader.result,
            base64: reader.result.split(",")[1],
            contentType: file.name.split(".")[1],
            size: file.size,
          });
        };
        reader.readAsDataURL(file);
      });
    },
    submitForm() {
      var chk = this.$refs.form.validate();
      this.form.errors = [];
      var sdStr = this.form.startDate + " " + this.form.startTime;
      var sd = new Date(sdStr);
      var edStr = this.form.endDate + " " + this.form.endTime;
      var ed = new Date(edStr);

      //console.log("startDate : " + sd);
      //console.log("endDate : " + ed);

      if (sd.getTime() > ed.getTime()) {
        this.form.errors.push(
          "กรุณาเลือกวันที่เริ่มการแสดงผลที่น้อยกว่าวันที่สิ้นสุดการแสดงผล"
        );
      }
      if (this.form.generateRefCode != 0)
      {
        for(var i = 0; i < this.form.generateRefCode.length; i++)
        {
          this.form.generateRefCodeList.push(this.form.generateRefCode[i].value);
        }
      }

      if (
        this.form.picture &&
        !this.form.pictureType.includes(this.form.picture.type)
      ) {
        this.form.errors.push(
          "กรุณาเลือกรูป Format .jpg, .jpeg, .png เท่านั้น"
        );
      }

      this.getFileBase64(this.form.picture).then(file => {
          let mb = 2*1024*1024;
          if(file.size > mb)
            this.form.errors.push("กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"); 

          if(this.form.errors.length > 0)
          {
            this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
            chk = false;
          }

          this.getFileBase64(this.form.pictureEN).then(fileEN => {
          let mb = 2*1024*1024;
          if(fileEN.size > mb)
            this.form.errors.push("กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"); 

          if(this.form.errors.length > 0)
          {
            this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
            chk = false;
          }

          if(chk) {
            this.postDataToApi(file , fileEN);
          }
       });
      });
    },
    resetForm() {
      this.$refs.form.reset();
    },
    postDataToApi(file , fileEN) {
      this.form.loading = true;
      this.form.errors = [];

      ApiService.setHeader();
      ApiService.post("/Api/Promotion/Add", {
        Name: this.form.name,
        Description: this.form.description,
        DescriptionEN: this.form.descriptionEN,
        Order: Number(this.form.order),
        StartDateString: this.form.startDateFormatted,
        EndDateString: this.form.endDateFormatted,
        StartTimeString: this.form.startTime,
        EndTimeString: this.form.endTime,
        Picture: file.base64,
        ContentType: file.contentType,
        pictureEN: fileEN.base64,
        ContentTypeEN: fileEN.contentType,
        InActiveStatus: !this.form.inActiveStatus,
        showOnPrivilegePage: this.form.showOnPrivilegePage,
        pointToRedeem: Number(this.form.pointToRedeem),
        GenerateRefCode: this.form.generateRefCodeList,
      })
        .then(({ data }) => {
          if (data.status) {
            // close dialog
            this.form.dialog = true;
          } else {
            this.form.dialog = false;
            this.form.loading = false;
            this.form.errors.push(!!data.message || "Unknow error occurs");
            this.$vuetify.goTo(this.$refs.alert, {
              duration: 1000,
              easing: "easeInOutCubic",
              offset: -20,
            });
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.form.errors.push(response.data.message);
          } else {
            this.form.errors.push("Unknow error occurs");
          }
          this.$vuetify.goTo(this.$refs.alert, {
            duration: 1000,
            easing: "easeInOutCubic",
            offset: -20,
          });
          this.form.dialog = false;
          this.form.loading = false;
        });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchPromotion" });
    },
    getGenerateRefCodeFromApi() {
      this.form.generateRefCodeLoading = true;
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/MemberCards/GenerateRefCodeOptions", {
          query: this.form.generateRefCodeSearch,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.generateRefCodeLoading = false;
          });
      });
    },
    remove (item) {
        const index = this.form.generateRefCode.indexOf(item);
        if (index >= 0) this.form.generateRefCode.splice(index, 1)
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PROMOTION.SECTION"), route: "/Promotion" },
      { title: this.$t("MENU.PROMOTION.ADD") },
    ]);
  },
};
</script>

<style lang="scss" scoped></style>
